import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { registerApplication } from 'single-spa';

//TEMPORAL
import { getUserService } from './app/services/UserService';
import { initializeGTM } from './app/services/GTM';
import { getInformationMessagesService } from './app/services/InformationMessagesService';
import { getTermsConditions } from './app/services/TermsConditionsService';

const GTM = 'GTM-PWLD9T7';
initializeGTM(GTM);

const microfronts = [
  {
    name: 'menu',
    app: '@prisma/menu',
    activeWhen: '/',
  },
  {
    name: 'header',
    app: '@prisma/header',
    activeWhen: '/',
  },
  {
    name: 'liquidation',
    app: '@prisma/liquidation',
    activeWhen: '/liquidaciones',
  },
  {
    name: 'online-transfers',
    app: '@prisma/online-transfers',
    activeWhen: '/transferencias',
  },
  {
    name: 'transaction',
    app: '@prisma/transaction',
    activeWhen: '/movimientos',
  },
  {
    name: 'ecommerce',
    app: '@prisma/ecommerce',
    activeWhen: '/venta',
  },
  {
    name: 'installment-plans',
    app: '@prisma/installment-plans',
    activeWhen: '/cuota-simple',
  },
  {
    name: 'rejection',
    app: '@prisma/rejection',
    activeWhen: '/rechazos',
  },
  {
    name: 'early',
    app: '@prisma/early-payment',
    activeWhen: '/cobro-anticipado',
  },
  {
    name: 'movement-premium',
    app: '@prisma/transaction-premium',
    activeWhen: '/premium/movimientos',
  },
  {
    name: 'report-premium',
    app: '@prisma/report-premium',
    activeWhen: '/premium/reportes',
  },
  {
    name: 'liquidation-premium',
    app: '@prisma/liquidation-premium',
    activeWhen: '/premium/liquidaciones',
  },
  {
    name: 'conciliation-premium',
    app: '@prisma/conciliation-premium',
    activeWhen: '/premium/conciliacion',
  },
  {
    name: 'collaborator',
    app: '@prisma/collaborator',
    activeWhen: '/colaboradores',
  },
  {
    name: 'lending',
    app: '@prisma/lending',
    activeWhen: '/prestamos',
  },
  {
    name: 'anses',
    app: '@prisma/anses',
    activeWhen: '/anses',
  },
  {
    name: 'terminal',
    app: '@prisma/terminal',
    activeWhen: '/terminales',
  },
  {
    name: 'profile',
    app: '@prisma/profile',
    activeWhen: '/perfil',
  },
  {
    name: 'establishment',
    app: '@prisma/establishment',
    activeWhen: '/establecimientos',
  },
  {
    name: 'automatic-debit',
    app: '@prisma/automatic-debit',
    activeWhen: '/debito-automatico',
  },
  {
    name: 'certificate',
    app: '@prisma/certificate',
    activeWhen: '/certificados',
  },
  {
    name: 'upgrade',
    app: '@prisma/upgrade',
    activeWhen: '/mi-payway-profesional',
  },
  {
    name: 'inicio',
    app: '@prisma/home',
    activeWhen: '/inicio',
  },
  {
    name: 'benefit',
    app: '@prisma/benefit',
    activeWhen: '/payway-club',
  },
];

const configureMicrofronts = (
  userRoles,
  informationMessages = [],
  permissions,
  termsConditions = true
) => {
  microfronts.map((config) =>
    registerApplication({
      ...config,
      app: () => window.System.import(config.app),
      customProps: () => {
        const informationMessagesMF = informationMessages.filter(
          (e) => e.name === config.name
        );
        return {
          userRoles: userRoles,
          informationMessages: informationMessagesMF,
          permissions: permissions,
          termsConditions: termsConditions,
          analytics: {
            GTM: GTM,
          },
        };
      },
    })
  );
};

//Implementación bien básica de validación de usuario logueado
getUserService()
  .then(async ({ data: user }) => {
    const { roles, permissions } = user;
    const { data: informationMessages } = await getInformationMessagesService();
    const { data: termsConditions } = await getTermsConditions(roles);

    configureMicrofronts(
      roles,
      informationMessages,
      permissions,
      termsConditions
    );

    ReactDOM.render(
      <React.StrictMode>
        <App user={{ ...user, termsConditions }} />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    const loginUrl = '/ms/ui-login/login';
    if (window.location.pathname !== loginUrl) {
      window.location = loginUrl;
    }
  });
