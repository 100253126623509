import { getAllowedQr } from '../../services/QrService';

export const GET_OFFER_QR = 'GET_OFFER_QR';
export const GET_OFFER_QR_REQUEST = 'GET_OFFER_QR_REQUEST';
export const GET_OFFER_QR_SUCCESS = 'GET_OFFER_QR_SUCCESS';
export const GET_OFFER_QR_FAILURE = 'GET_OFFER_QR_FAILURE';

export const getOfferQr = () => ({
  type: GET_OFFER_QR,
  promise: getAllowedQr(),
});
