import { USER_ROLE_COLLABORATOR } from '../utils/Constants';

export const routesFree = {
  start: '/inicio',
  liquidations: {
    daily: '/liquidaciones/diarias',
    dailyDetail: '/liquidaciones/diarias/detalle',
    monthly: '/liquidaciones/mensuales',
    monthlyDetail: '/liquidaciones/mensuales/detalle',
    others: '/liquidaciones/otras',
  },
  online_transfers: {
    daily: '/transferencias/diarias',
  },
  movements: {
    presented: '/movimientos/presentados',
    live: '/movimientos/en-linea',
    filed_and_settled: '/movimientos/presentados-y-liquidados',
  },
  payment: {
    button: {
      base: '/venta/boton',
      history: '/venta/boton/historial',
    },
    qr: {
      base: '/venta/qr',
      history: '/venta/qr/historial',
      branches: '/venta/qr/sucursales',
      checkouts: '/venta/qr/sucursales/:branch_name/qrs',
    },
  },
  early_payment: '/cobro-anticipado',
  collaborators: '/colaboradores',
  installment_plans: '/cuota-simple',
  lending: '/prestamos',
  anses: '/anses',
  terminals: {
    base: '/terminales',
    create: '/terminales/nueva',
    payments: '/terminales/cobros',
    instructive: '/terminales/instructivo',
    visitTracking: '/terminales/soporte-tecnico/seguimiento-visitas',
    support: '/terminales/soporte-tecnico',
    displaySupport: '/terminales/soporte-tecnico/pantalla',
    displaySupportTechnicalVisit:
      '/terminales/soporte-tecnico/pantalla/visita-tecnica',
    displayDiagnosisSupport: '/terminales/soporte-tecnico/pantalla/diagnostico',
    displayDiagnosisSupportTechnicalVisit:
      '/terminales/soporte-tecnico/pantalla/diagnostico/visita-tecnica',
    displaySolutions:
      '/terminales/soporte-tecnico/pantalla/soluciones-posibles',
    displaySolutionsTechnicalVisit:
      '/terminales/soporte-tecnico/pantalla/soluciones-posibles/visita-tecnica',
    cable: '/terminales/soporte-tecnico/cable',
    cableTechnicalVisit: '/terminales/soporte-tecnico/cable/visita-tecnica',
    charger: '/terminales/soporte-tecnico/cargador',
    chargerTechnicalVisit:
      '/terminales/soporte-tecnico/cargador/visita-tecnica',
    chip: '/terminales/soporte-tecnico/chip',
    chipTechnicalVisit: '/terminales/soporte-tecnico/chip/visita-tecnica',
    keyboard: '/terminales/soporte-tecnico/teclado',
    keyboardTechnicalVisit:
      '/terminales/soporte-tecnico/teclado/visita-tecnica',
    possibleSolutionsDisplay:
      '/terminales/soporte-tecnico/pantalla/soluciones-posibles',
    possibleSolutionsDisplayTechnicalVisit:
      '/terminales/soporte-tecnico/pantalla/soluciones-posibles/visita-tecnica',
    reader: '/terminales/soporte-tecnico/lector',
    readerTechnicalVisit: '/terminales/soporte-tecnico/lector/visita-tecnica',
    printer: '/terminales/soporte-tecnico/impresora',
    printerTechnicalVisit:
      '/terminales/soporte-tecnico/impresora/visita-tecnica',
    battery: '/terminales/soporte-tecnico/bateria',
    batteryTechnicalVisit: '/terminales/soporte-tecnico/bateria/visita-tecnica',
    addBrand: '/terminales/agregar',
  },
  profile: {
    base: '/perfil',
    acquisitionCredentials: '/perfil/gestionar-crendeciales-adquirencia',
    addCuit: '/perfil/agregar-cuit',
    compliance: '/perfil/datos',
    myCommerces: '/perfil/mis-comercios',
    myFile: '/perfil/mi-legajo',
    myAccount: '/perfil/mis-cuentas',
    professionalPayway: '/perfil/mi-payway-profesional',
  },
  establishment: {
    base: '/establecimientos',
    create: '/establecimientos/nuevo',
  },
  debaut: {
    base: '/debito-automatico',
    presentations: '/debito-automatico/presentaciones',
    schedules: '/debito-automatico/cronograma',
    presentation: '/debito-automatico/cronograma/presentacion',
  },
  certificate: {
    base: '/certificados',
    details: '/certificados/detalles',
  },
  upgrade: {
    base: '/mi-payway-profesional',
    success: '/mi-payway-profesional/confirmacion/finalizado',
  },
  benefits: {
    base: '/payway-club',
  },
};

export const routesPremium = {
  ...routesFree,
  liquidations: {
    daily: '/premium/liquidaciones/diarias',
    dailyDetail: '/premium/liquidaciones/diarias/detalle',
    monthly: '/premium/liquidaciones/mensuales',
    monthlyDetail: '/premium/liquidaciones/mensuales/detalle',
    others: '/premium/liquidaciones/otras',
  },
  movements: {
    liquidated: '/premium/movimientos/liquidados',
    presented: '/premium/movimientos/presentados',
    online: '/premium/movimientos/en-linea',
    onlineSales: '/premium/movimientos/ventas-online',
    filed_and_settled: '/movimientos/presentados-y-liquidados',
  },
  reports: {
    base: '/premium/reportes',
    download: '/premium/reportes/generados',
    schedule: '/premium/reportes/programados',
  },
  terminals: {
    base: '/terminales',
    create: '/terminales/nueva',
    instructive: '/terminales/instructivo',
  },
  conciliation: {
    invoice: '/premium/conciliacion/facturacion',
    invoiceDetail: '/premium/conciliacion/facturacion/detalle',
    invoiceUpload: '/premium/conciliacion/facturacion/cargar',
    onlineSales: '/premium/conciliacion/ventas-online',
    onlineSalesDetail: '/premium/conciliacion/ventas-online/detalle',
  },
};

export const forbiddenRoutesPerRole = new Map([
  [
    USER_ROLE_COLLABORATOR,
    {
      early_payment: '/cobro-anticipado',
      collaborators: '/colaboradores',
      anses: '/anses',
      lending: '/prestamos',
    },
  ],
]);
