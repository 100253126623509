import {
  GET_ACCESSTOKEN_REQUEST,
  GET_ACCESSTOKEN_SUCCESS,
  GET_ACCESSTOKEN_FAILURE,
} from '../actions/ActionGetAccessToken.js';

const initialState = {
  completed: false,
  error: false,
};

const accessTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESSTOKEN_REQUEST:
      return {
        ...state,
        completed: false,
        error: false,
      };
    case GET_ACCESSTOKEN_SUCCESS:
      return {
        ...state,
        completed: true,
        error: false,
        accessToken: action.resp.data.access_token,
      };
    case GET_ACCESSTOKEN_FAILURE: {
      //TODO: En caso de error volver a setear el radiobutton en el cuit anterior
      let msg = 'Tuvimos un error en nuestro sistema';
      return {
        ...state,
        completed: true,
        error: true,
        status_code: action.error.status,
        msg: msg,
      };
    }
    default:
      return state;
  }
};

export default accessTokenReducer;
