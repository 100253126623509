export const USER_TYPE_FREE = 'USER_TYPE_FREE';
export const USER_TYPE_PREMIUM = 'USER_TYPE_PREMIUM';
export const USER_ROLE_PREMIUM = 'ROLE_phe-premium';
export const USER_ROLE_COLLABORATOR = 'ROLE_collaborator';
export const JWT_ROLE_COLLABORATOR = 'collaborator';
export const JWT_ROLE_PREMIUM = 'phe-premium';
export const BACKOFFICE_REMOTE_LOGIN_ADMIN = 'ROLE_backoffice-remote-login';
export const BACKOFFICE_REMOTE_LOGIN_COLLAB =
  'ROLE_backoffice-remote-login-collaborator';
export const MAX_COMMERCES = 25;
export const NOTIFICATIONS_INTERVAL_MS = 600000;
