import { getAllowedLending } from '../../services/LendingService';

export const GET_OFFER_LENDING = 'GET_OFFER_LENDING';
export const GET_OFFER_LENDING_REQUEST = 'GET_OFFER_LENDING_REQUEST';
export const GET_OFFER_LENDING_SUCCESS = 'GET_OFFER_LENDING_SUCCESS';
export const GET_OFFER_LENDING_FAILURE = 'GET_OFFER_LENDING_FAILURE';

export const getOfferLending = () => ({
  type: GET_OFFER_LENDING,
  promise: getAllowedLending(),
});
