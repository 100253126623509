import React from 'react';
import './App.css';
import Layout from './app/components/Layout/Layout';
import { USER_ROLE_PREMIUM } from './utils/Constants';
import { BrowserRouter } from 'react-router-dom';
import { PermissionProvider } from '@phe/ui-components';
import { updateGlobalTermsConditions } from './hooks/useTermsConditions';

function App({ user }) {
  const { roles, permissions, termsConditions } = user;
  let isPremium = roles.includes(USER_ROLE_PREMIUM);

  updateGlobalTermsConditions({ ...termsConditions });

  return (
    <BrowserRouter>
      <PermissionProvider permissions={permissions} roles={roles}>
        <Layout
          isPremium={isPremium}
          header={<div id='single-spa-application:header'></div>}
          left={<div id='single-spa-application:menu'></div>}
          right={
            <>
              <div id='single-spa-application:transaction'></div>
              <div id='single-spa-application:liquidation'></div>
              <div id='single-spa-application:chargeback'></div>
              <div id='single-spa-application:ecommerce'></div>
              <div id='single-spa-application:dashboard'></div>
              <div id='single-spa-application:automatic-debit'></div>
              <div id='single-spa-application:rejection'></div>
              <div id='single-spa-application:early'></div>
              <div id='single-spa-application:movement-premium'></div>
              <div id='single-spa-application:report-premium'></div>
              <div id='single-spa-application:collaborator'></div>
              <div id='single-spa-application:installment-plans'></div>
              <div id='single-spa-application:liquidation-premium'></div>
              <div id='single-spa-application:dashboard-premium'></div>
              <div id='single-spa-application:conciliation-premium'></div>
              <div id='single-spa-application:anses'></div>
              <div id='single-spa-application:lending'></div>
              <div id='single-spa-application:terminal'></div>
              <div id='single-spa-application:profile'></div>
              <div id='single-spa-application:establishment'></div>
              <div id='single-spa-application:certificate'></div>
              <div id='single-spa-application:online-transfers'></div>
              <div id='single-spa-application:upgrade'></div>
              <div id='single-spa-application:inicio'></div>
              <div id='single-spa-application:benefit'></div>
              <div id='single-spa-application:modal-validator'></div>
              <div id='single-spa-application:help-center'></div>
            </>
          }
        ></Layout>
      </PermissionProvider>
    </BrowserRouter>
  );
}

export default App;
