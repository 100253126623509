import {
  GET_OFFER_EARLY_PAYMENT_REQUEST,
  GET_OFFER_EARLY_PAYMENT_SUCCESS,
  GET_OFFER_EARLY_PAYMENT_FAILURE,
} from '../actions/ActionGetOfferEarlyPayments';

const initialState = {
  completed: false,
  error: false,
  sent: true,
  offer: false,
};
const earlyPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_EARLY_PAYMENT_REQUEST: {
      return {
        ...state,
        completed: false,
        error: false,
        sent: true,
      };
    }
    case GET_OFFER_EARLY_PAYMENT_SUCCESS: {
      return {
        ...state,
        completed: true,
        error: false,
        offer: action.resp.data.offer,
      };
    }
    case GET_OFFER_EARLY_PAYMENT_FAILURE: {
      let msg = 'Tuvimos un error en nuestro sistema';

      return {
        ...state,
        completed: true,
        error: true,
        status_code: action.error.status,
        msg: msg,
      };
    }
    default:
      return state;
  }
};

export default earlyPaymentsReducer;
