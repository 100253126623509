import TagManager from 'react-gtm-module';

export const initializeGTM = (idGTM) => {
  const tagManagerArgs = {
    gtmId: idGTM,
  };
  TagManager.initialize(tagManagerArgs);
};

export const insertDataLayerPageView = (pagePath) => {
  const data = {
    dataLayer: {
      event: 'ga_pageview',
      pagePath,
    },
  };
  TagManager.dataLayer(data);
};

export const insertDataLayerEvent = (cuit, isPremium, isCollaborator) => {
  const data = {
    dataLayer: {
      event: 'ga_event_info_cuit',
      idNegocio: cuit,
      tipoNegocio: isPremium ? 'Premium' : 'Free',
      rolUsuario: isCollaborator ? 'Colaborador' : 'Administrador',
    },
  };
  TagManager.dataLayer(data);
};
