import {
  GET_OFFER_LENDING_REQUEST,
  GET_OFFER_LENDING_SUCCESS,
  GET_OFFER_LENDING_FAILURE,
} from '../actions/ActionGetOfferLending';

const initialState = {
  completed: false,
  error: false,
  allowed: false,
};

const lendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_LENDING_REQUEST: {
      return initialState;
    }

    case GET_OFFER_LENDING_SUCCESS: {
      return {
        completed: true,
        error: false,
        allowed: action.resp?.status === 204,
      };
    }

    case GET_OFFER_LENDING_FAILURE: {
      let msg = 'Tuvimos un error llamando al servicio de prestamos';

      return {
        ...state,
        completed: true,
        error: true,
        status_code: action.error.status,
        msg: msg,
      };
    }
    default:
      return state;
  }
};

export default lendingReducer;
