import {
  getNotificationsService,
  readNotificationsService,
} from '../../services/NotificationService';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
  promise: getNotificationsService(),
});

export const readNotifications = (notifications) => ({
  type: READ_NOTIFICATIONS,
  promise: readNotificationsService(notifications),
});
