import {
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
} from '../actions/ActionNotification';

const initialState = {
  notifications: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.resp.data,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: null,
      };

    default:
      return state;
  }
};

export default notificationReducer;
