import {
  GET_OFFER_PAYSTORE_FAILURE,
  GET_OFFER_PAYSTORE_REQUEST,
  GET_OFFER_PAYSTORE_SUCCESS,
} from '../actions/ActionGetOfferPaystore';

const initialState = {
  offer: false,
};

const paystoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_PAYSTORE_REQUEST:
      return {
        ...state,
      };
    case GET_OFFER_PAYSTORE_SUCCESS:
      return {
        ...state,
        offer: true,
      };
    case GET_OFFER_PAYSTORE_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default paystoreReducer;
