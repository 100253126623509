import { getOfferInstallmentPlansService } from '../../services/InstallmentPlansService';

export const GET_OFFER_INSTALLMENT_PLANS = 'GET_OFFER_INSTALLMENT_PLANS';
export const GET_OFFER_INSTALLMENT_PLANS_REQUEST =
  'GET_OFFER_INSTALLMENT_PLANS_REQUEST';
export const GET_OFFER_INSTALLMENT_PLANS_SUCCESS =
  'GET_OFFER_INSTALLMENT_PLANS_SUCCESS';
export const GET_OFFER_INSTALLMENT_PLANS_FAILURE =
  'GET_OFFER_INSTALLMENT_PLANS_FAILURE';

export const getOfferInstallmentPlans = () => ({
  type: GET_OFFER_INSTALLMENT_PLANS,
  promise: getOfferInstallmentPlansService(),
});
