import {
  GET_USER_COMMERCES_SUCCESS,
  GET_USER_COMMERCES_FAILURE,
  GET_USER_COMMERCES_REQUEST,
} from '../actions/ActionGetUserCommerces';

const initialState = {
  completed: false,
  error: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_COMMERCES_REQUEST: {
      return {
        ...state,
        completed: false,
        error: false,
      };
    }

    case GET_USER_COMMERCES_SUCCESS: {
      return {
        completed: true,
        error: false,
        commerces: action.resp.data,
      };
    }

    case GET_USER_COMMERCES_FAILURE: {
      let msg = 'Tuvimos un error en nuestro sistema';

      return {
        ...state,
        completed: true,
        error: true,
        status_code: action.error.status,
        msg: msg,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
