import { combineReducers } from 'redux';
import earlyPaymentsReducer from './EarlyPaymentsReducer';
import notificationReducer from './NotificationReducer';
import installmentPlansReducer from './InstallmentPlansReducer';
import paystoreReducer from './PaystoreReducer';
import lendingReducer from './LendingReducer';
import qrReducer from './QrReducer';
import userReducer from './UserReducer';
import accessTokenReducer from './AccessTokenReducer';

export default combineReducers({
  earlyPayments: earlyPaymentsReducer,
  notifications: notificationReducer,
  installmentPlans: installmentPlansReducer,
  paystore: paystoreReducer,
  lending: lendingReducer,
  user: userReducer,
  accessToken: accessTokenReducer,
  qr: qrReducer,
});
