import { getOfferPaystoreService } from '../../services/PaystoreService';

export const GET_OFFER_PAYSTORE = 'GET_OFFER_PAYSTORE';
export const GET_OFFER_PAYSTORE_REQUEST = 'GET_OFFER_PAYSTORE_REQUEST';
export const GET_OFFER_PAYSTORE_SUCCESS = 'GET_OFFER_PAYSTORE_SUCCESS';
export const GET_OFFER_PAYSTORE_FAILURE = 'GET_OFFER_PAYSTORE_FAILURE';

export const getOfferPaystore = () => ({
  type: GET_OFFER_PAYSTORE,
  promise: getOfferPaystoreService(),
});
