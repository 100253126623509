import axios from 'axios';
import { getAuthCookie } from './Utils';

const buildAuthorizationHeader = () => {
  let authToken = getAuthCookie();
  let headers = {
    Authorization: 'Bearer ' + authToken,
  };

  return headers;
};

const secureGet = (url, { params }) => {
  let headers = buildAuthorizationHeader();
  return axios.get(url, { params, headers });
};

const securePut = (url, params) => {
  let headers = buildAuthorizationHeader();
  return axios.put(url, params, { headers });
};

const securePost = (url, params) => {
  let headers = buildAuthorizationHeader();
  return axios.post(url, params, { headers });
};

export { secureGet, securePut, securePost };
