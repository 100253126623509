import { useEffect, useState } from 'react';
import { getUserService } from '../app/services/UserService';

const useUser = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    getUserService().then(({ data }) => setUser(data));
  }, []);

  return user;
};

export default useUser;
