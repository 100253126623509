import { USER_ROLE_PREMIUM } from '../../utils/Constants';
import { secureGet } from '../../utils/HttpUtils';

export const getTermsConditions = (roles) => {
  return new Promise((resolve) => {
    if (roles.includes(USER_ROLE_PREMIUM)) {
      secureGet('/api/bff-user/private/user/terms-and-conditions', {})
        .then((data) => resolve(data))
        .catch(() => resolve({ data: { accepted: true } }));
    } else {
      resolve({ data: { accepted: true } });
    }
  });
};
