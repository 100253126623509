import {
  GET_OFFER_QR_REQUEST,
  GET_OFFER_QR_SUCCESS,
  GET_OFFER_QR_FAILURE,
} from '../actions/ActionGetOfferQr';

const initialState = {
  completed: false,
  error: false,
  qualified: false,
};

const lendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_QR_REQUEST: {
      return initialState;
    }

    case GET_OFFER_QR_SUCCESS: {
      return {
        completed: true,
        error: false,
        qualified: action.resp?.data.qualified,
      };
    }

    case GET_OFFER_QR_FAILURE: {
      let msg = 'Tuvimos un error llamando al servicio de product qr';

      return {
        ...state,
        completed: true,
        error: true,
        status_code: action.error.status,
        msg: msg,
      };
    }
    default:
      return state;
  }
};

export default lendingReducer;
