import { getOfferEarlyPaymentService } from '../../services/EarlyPaymentService';

export const GET_OFFER_EARLY_PAYMENT = 'GET_OFFER_EARLY_PAYMENT';
export const GET_OFFER_EARLY_PAYMENT_REQUEST =
  'GET_OFFER_EARLY_PAYMENT_REQUEST';
export const GET_OFFER_EARLY_PAYMENT_SUCCESS =
  'GET_OFFER_EARLY_PAYMENT_SUCCESS';
export const GET_OFFER_EARLY_PAYMENT_FAILURE =
  'GET_OFFER_EARLY_PAYMENT_FAILURE';

export const getOfferEarlyPayment = () => ({
  type: GET_OFFER_EARLY_PAYMENT,
  promise: getOfferEarlyPaymentService(),
});
