import { getUserCommerces } from '../../services/UserService';

export const GET_USER_COMMERCES = 'GET_USER_COMMERCES';
export const GET_USER_COMMERCES_REQUEST = 'GET_USER_COMMERCES_REQUEST';
export const GET_USER_COMMERCES_SUCCESS = 'GET_USER_COMMERCES_SUCCESS';
export const GET_USER_COMMERCES_FAILURE = 'GET_USER_COMMERCES_FAILURE';

export const actionUserCommerces = (userId) => ({
  type: GET_USER_COMMERCES,
  promise: getUserCommerces(userId),
});
