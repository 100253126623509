import { secureGet } from '../../utils/HttpUtils';

let userData = {};

export const getUserService = () => {
  if (Object.keys(userData).length > 0) return Promise.resolve(userData);

  return secureGet(`/api/bff-user/private/user`, {}).then((data) => {
    userData = data;
    return userData;
  });
};

export const getUserCommerces = () =>
  secureGet(`/api/bff-user/private/user/commerces`, {});
