import { getAccessToken } from '../../services/SecurityService';

export const GET_ACCESSTOKEN = 'GET_ACCESSTOKEN';
export const GET_ACCESSTOKEN_REQUEST = 'GET_ACCESSTOKEN_REQUEST';
export const GET_ACCESSTOKEN_SUCCESS = 'GET_ACCESSTOKEN_SUCCESS';
export const GET_ACCESSTOKEN_FAILURE = 'GET_ACCESSTOKEN_SUCCESS_FAILURE';

export const actionGetAccessToken = (cuit) => ({
  type: GET_ACCESSTOKEN,
  promise: getAccessToken(cuit),
});
