import { useHistory } from 'react-router-dom';
import { USER_ROLE_PREMIUM } from '../../../../utils/Constants';
import useUser from '../../../../hooks/useUser';
import { getGlobalTermsCondition } from '../../../../hooks/useTermsConditions';

import {
  forbiddenRoutesPerRole,
  routesFree,
  routesPremium,
} from '../../../routes';
import { start } from 'single-spa';

const SecurityRouter = ({ children }) => {
  let history = useHistory();
  const user = useUser();
  const isPremiumUser = () => user?.roles?.includes(USER_ROLE_PREMIUM);
  const termsConditions = getGlobalTermsCondition();

  const getRoute = (json, path = []) => {
    if (path.length === 0) return json;
    let firstPath = path.shift();
    if (json[firstPath]) {
      return getRoute(json[firstPath], path);
    } else {
      return undefined;
    }
  };

  const filterRoutesByRole = (routes, user) => {
    let newRoutes = {};
    Object.assign(newRoutes, routes);
    forbiddenRoutesPerRole.forEach((forbiddenRoutes, role) => {
      if (user.roles.includes(role)) {
        Object.keys(forbiddenRoutes).forEach((route) => {
          delete newRoutes[route];
        });
      }
    });
    return newRoutes;
  };

  const getRouteMatchedWith = (url, routes, path = []) => {
    let filteredRoutes = filterRoutesByRole(routes, user);
    const found =
      routes &&
      Object.entries(filteredRoutes).find(([k, v]) => {
        if (v === url) return path.push(k);
        if (typeof v === 'object') {
          const tmp = getRouteMatchedWith(url, v, [...path, k]);
          if (tmp) return (path = tmp);
        }
      });
    if (found) return path;
  };

  const isPremiumUrl = () =>
    getRouteMatchedWith(history.location.pathname, routesPremium);
  const isFreeUrl = () =>
    getRouteMatchedWith(history.location.pathname, routesFree);

  const redirect = (routes, path) => {
    if (path) {
      let redirectUrl = getRoute(routes, path);
      if (redirectUrl) {
        history.push(redirectUrl);
        return true;
      } else {
        history.push('/inicio');
        return true;
      }
    } else {
      history.push('/inicio');
      return true;
    }
  };

  const redirectToPremium = () => {
    if (
      typeof termsConditions === 'object' &&
      !termsConditions?.accepted &&
      history.location.pathname !== routesPremium['start']
    ) {
      redirect(routesPremium, ['start']);
    }
    return isPremiumUrl() || redirect(routesPremium, isFreeUrl());
  };

  const redirectToFree = () =>
    isFreeUrl() || redirect(routesFree, isPremiumUrl());

  const isValidUser = () => Object.keys(user).length !== 0;

  const isSecureRender = () => {
    if (!isValidUser()) return false;
    if (isPremiumUser()) return redirectToPremium();
    return redirectToFree();
  };

  if (!isSecureRender()) {
    return false;
  }

  start();
  return children;
};

export default SecurityRouter;
